import React from "react";

const Footer = () => {

  // const pathName = ['/', '/print', 'display'];
  // const isPathName = pathName.includes(window.location.pathname)
  const isPathName = false

  return (
    <>
      <section className="footer bg-purple-500 relative">
        <div className="container bottom-0 left-0 fixed">
          <div className="flex flex-wrap items-center justify-between py-4 mx-auto gap-4 ">
            {
              isPathName && (
                <button className='h-20 bg-purple-rsia w-1/5 rounded-md'>
                  <span className='font-bold text-white'>
                     BACK
                  </span>
                </button>
              )
            }

          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
