import React, {useEffect, useState} from 'react';

function Kamar() {

  const [datas, setDatas] = useState([]);

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_API_URL}/getKamar`)
      .then(response => response.json())
      .then(r => setDatas(r.data))
  }, [])


  return (

    <div className='m-3'>
      <h1 className='font-extrabold text-center text-dark text-3xl mb-3'>DAFTAR KETERSEDIAAN KAMAR RAWAT INAP</h1>
      <marquee direction='up' height='500px' scrollamount="3">
        <div className='flex flex-wrap text-center'>
          {
            datas.map((el,k) => (
              <div className='w-1/3 my-4' key={k + 1}>
                <div className='bg-red-rsia mr-2 p-3'>
                  <span className='text-white font-extrabold text-3xl'>{el.nama_kelas}</span>
                </div>
                {
                  el.list_kelas && (el.list_kelas || []).map(c=> (
                    <div className='flex bg-purple-rsia mr-2 p-3 h-20'>
                      <div className='flex-col w-2/3 text-left'><span className='text-white font-extrabold text-2xl'>{c.nama_kamar}</span></div>
                      <div className={`flex-col w-1/3 ${+c.jumlah_bed === 0 ?  "bg-red-500": "bg-green-500"}  h-full`}><span className='text-white font-extrabold text-5xl'>{c.jumlah_bed}</span></div>
                    </div>

                  ))
                }



              </div>
            ))
          }
        </div>
      </marquee>

    </div>

  );
}

export default Kamar;
