import React, {useRef, forwardRef } from "react";
import {useReactToPrint} from "react-to-print";
import dayjs from "dayjs";
import {useSearchParams, useNavigate} from "react-router-dom";


const Print = () => {
  const componentRef = useRef();

  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();


  const queue = search.get('queue')
  const n = search.get('poli')
  const isNew = search.get('new')
  


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      navigate('/') // Navigate ke Halaman Utama selete
    }
  });

  return (
    <div>
      <div className="dark:bg-gray-900 lg:text-center">
        <div
          className="max-w-7xl mx-auto px-4 lg:py-5 lg:px-8 lg:items-center lg:text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block dark:text-skin-white">No Antrian Anda</span>
          </h2>
        </div>

        <div className="text-dark font-extrabold text-9xl m-auto py-10">
          {queue}
        </div>

        <button
          onClick={handlePrint}
          className='bg-red-rsia h-20 w-1/4 rounded-md'
          // @ts-ignore
          disabled={queue === ""}
        >
          <span className='text-white font-extrabold text-2xl'>CETAK NO ANTRIAN</span>
        </button>
        <div className='hidden'>
          <ComponentToPrint
            // @ts-ignore
            ref={componentRef}
            // @ts-ignore
            queue={queue}
            n={n}
            isNew={isNew}
          />
        </div>
        <div className="text-dark py-12 font-extrabold text-xl m-auto">
          {isNew === "1" ? (<p className='text-2xl mt-3'>PASIEN BARU</p>):''}
          <p>Silakan Menunggu Nomor Anda Dipanggil</p>
          <p>Terima Kasih Atas Kunjungan Anda</p>
        </div>

      </div>
    </div>
  );
};

export default Print;

// eslint-disable-next-line react/display-name
// @ts-ignore
// eslint-disable-next-line react/display-name
export const ComponentToPrint = forwardRef(({queue, n, isNew}, ref) => {

  const now = dayjs(new Date()).locale('id-ID')
  return (
    <div
      // @ts-ignore
      ref={ref}
      className='w-72mm m-2 text-center'
    >
      <header className='mb-4 text-center'>
        <p className='text-xl font-bold break-words'>
          RUMAH SAKIT UMUM DAERAH
        </p>
        <p className='text-xl font-bold break-words'>
          DABO SINGKEP
        </p>

      </header>
      <section>
        <div className='flex justify-between'>
          <span>{now.format('dddd, DD MMM YYYY')}</span>
          <span>{now.format('HH:mm:ss')}</span>
        </div>

        <p className='text-2xl mt-3'>No Antrian Anda</p>
        <p className='text-5xl font-extrabold py-1'>{queue}</p>
        <p className='font-extrabold py-2'>{n}</p>
      </section>
      <footer className='text-sm'>
        {isNew === "1" ? (<p className='text-2xl mt-3'>PASIEN BARU</p>):''}
        <p>Silakan Menunggu Nomor Anda Dipanggil</p>
        <p>Terima Kasih Atas Kunjungan Anda</p>
      </footer>
      <p>*************</p>
    </div>
  );
});
