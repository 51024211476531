import React from 'react'
import logoRSIA from './rsia.png'

const Logo = () => {
  return (
    <div className="flex">
      <div>
        <img src={logoRSIA} alt='logo' style={{width: '50px'}} />
      </div>
      <div>
        <h3 className="text-white">
          RUMAH SAKIT UMUM DAERAH
        </h3>
        <h3 className="text-white">
          D A B O
        </h3>
      </div>

    </div>
  )
}

export default Logo
