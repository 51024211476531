import React, {useState, useEffect} from "react";
import Logo from "./logo";
import Clock from 'react-digital-clock'
import dayjs from "dayjs";

const Header = () => {
  const now = dayjs(new Date())
  const INTERVAL = 10800000 // in miliseconds (3 jam)


  const [dateState, setDateState] = useState(now);

  useEffect(() => {
    setInterval(() => setDateState(dayjs(new Date())), INTERVAL);
  }, []);


  return (
    <>
      <section className="header bg-green-500 border-b dark:bg-gray-900 dark:border-opacity-10">
        <div className="container mx-auto ">
          <div className="flex flex-wrap items-center justify-between py-4 mx-auto gap-4">
            <div className="flex-none z-10	">
              <Logo />
            </div>
            <div className="flex text-4xl gap-4">
            <span className='text-white'>
              {dateState.format('DD MMM YYYY')}
            </span>
              <span>
                <Clock locale= {'id-ID'} hour12= {false} />
              </span>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default Header;
