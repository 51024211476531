import React from "react";
import {Link} from "react-router-dom";

export default function Home() {
  return (
    <div className="">
      <div className="dark:bg-gray-900">
        <div
          className="max-w-7xl mx-auto px-4 lg:py-10 lg:px-8 lg:items-center lg:text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block dark:text-skin-white">ANJUNGAN PASIEN MANDIRI</span>
          </h2>
        </div>

        <div className="max-w-7xl mx-auto px-4 lg:py-4 lg:px-8 lg:items-center lg:text-center">
          <h5 className="font-extrabold tracking-tight text-gray-900">
            <span className="dark:text-skin-white text-2xl">Silakan Menyentuh Tombol Di Bawah Ini</span>
            <span className="dark:text-skin-white block text-2xl">Untuk Mendapatkan No Antrian</span>
          </h5>
        </div>


        <div className="lg:items-center lg:text-center">
          <Link to='/pasienbpjs'>
            <button className='btn bg-red-rsia w-1/2 h-20 rounded-md'>
              <span className='text-xl font-extrabold tracking-tight text-white'>PASIEN BPJS</span>
            </button>
          </Link>

        </div>
        
        <div className="lg:items-center lg:text-center lg:py-3">
          <Link to='/pasienumum'>
            <button className='btn bg-red-rsia w-1/2 h-20 rounded-md'>
              <span className='text-xl font-extrabold tracking-tight text-white'>PASIEN UMUM</span>
            </button>
          </Link>

        </div>

        <div className="lg:items-center lg:text-center lg:py-5">
          <Link to='/pasienmanual'>
            <button className='btn bg-red-rsia w-1/2 h-20 rounded-md'>
              <span className='text-xl font-extrabold tracking-tight text-white'>ANTREAN BPJS MANUAL</span>
            </button>
          </Link>

        </div>

      </div>


    </div>
  )
}

