import React, {useState, useEffect} from "react";
// import {Provider} from 'react-redux';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";


function MyApp({children,pageProps}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 250);

    return () => {
      setLoading(true);
    };
  }, [pageProps]);




  return (
    <>
      <div className="h-screen">
        <Header/>
        <section className={`${loading ? "dark:animate-none animate-Loading" : ""}`}>
          {children}
        </section>
      </div>
      <Footer />
    </>
  );
}

export default MyApp;
