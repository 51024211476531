import Echo from "laravel-echo";
import {useState} from "react";
// import VIDEO from './video.mp4'
import dayjs from "dayjs";

window.Echo = new Echo({
  broadcaster: 'socket.io',
  // client: "",
  host: `${process.env.REACT_APP_API_URL_SOCKET}`
});


const regExTest = (txt="") => {
  const splitting = txt.split("-")
  const filtering = splitting && splitting[0] // get first index (0)
  const pattern = /^[A-Za]*$/ // GET Letter only
  const isMatch = pattern.test(filtering) // void: Boolean

  if (isMatch) {
    return txt.replace("-", ",")
  }

  if(!isMatch) {
    return txt
  }
}

function DisplayQueue() {

  const [datas, setDatas] = useState([]);
  const [antrian, setAntrian] = useState([]);
  const [noAntrian, setNoAntrian] = useState('-');
  const [loket, setLoket] = useState(0);
  const [call, setCall] = useState(false);


  window.Echo
    .channel(`laravel_database_callAntrian`) // Broadcast channel name
    .listen('RequestCallAntrian', (e) => { // Message name
      setDatas(e.data)
      setAntrian([...antrian, {loket: e.loket, queue: antrian.length + 1 ,noAntrian:e.noAntrian, time: dayjs(new Date()).format('ss')}]);
      }
    );

  const video = document.getElementById("myVideo") || {volume: 0.2};

  const setVolume = (volume=0.1) => video.volume = volume

  const handlePlay = A => {
    setCall(A.length > 0)
   let offset = 0
    A.forEach((k, i) => {
      setTimeout(() => {
        window.responsiveVoice
              .speak(`Nomor Antrian, ${regExTest(A[i].noAntrian || "")} menuju, ${A[i].loket}`, `Indonesian Female`, {
            rate: 0.9,
            pitch: 1,
            volume: 1,
            onstart: () => {
              setCall(true)
              setNoAntrian(k.noAntrian)
              setLoket(A[i].loket)
              setVolume(0.0)
            },
            onend: () => {
              setCall(A.length < 0)
              setVolume(0.2)
            }
          })
      },  A.length === 1 ? 0 : offset + 6000 )
      offset += 6000
      setAntrian([])
      setVolume(0.2)
    })
  }

  if(antrian.length > 0 && !call) {
    handlePlay(antrian)
  }

  console.log(datas, 'DATASS')
  console.log(antrian, 'antrian')
  return (
    <div className="app">
      <div>
        <div className="dark:bg-gray-900 lg:text-center flex mx-6 mt-6">
          <div className='flex-row w-5/12'>
            <div className='flex-wrap'>
              <div className='flex-col'>
                <div className='bg-purple-rsia h-24 rounded'>
                  <div className='p-1'>
                    <h1 className='font-extrabold text-xl text-white'>No Antrian</h1>
                    <h1 className='font-extrabold text-3xl text-white animate-pulse'>{noAntrian}</h1>
                    <h1 className='font-extrabold text-xl text-white my-1'>{loket}</h1>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className='overflow-auto' style={{height: '375px'}}>*/}
            <div>
              {
                datas.map((el, k) => (
                  <div className='flex my-2 rounded' key={k + 1}>
                    <div className='w-1/3 bg-red-rsia flex h-16 rounded mr-2'>
                      <span className='text-2xl font-extrabold m-auto text-white'>
                        {el.nomorAntrian}
                      </span>
                    </div>
                    <div className='w-2/3 bg-purple-600 h-16 flex rounded'>
                      <div className='m-auto'>
                        <h1 className='text-xl font-bold text-white'>{el.namaLoket}</h1>
                        {/* <h1 className='text-xl font-bold text-white'>{el.namaPasien}</h1> */}
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>

          </div>
          <div className='w-7/12 flex-row p-3 h-full flex'>
            <div className=''>
              <video id="myVideo" width="720" height="400" controls autoPlay loop muted>
                <source src={process.env.PUBLIC_URL + '/video.mp4'} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

   </div>
  );
}

export default DisplayQueue;

