import {useState, useEffect} from "react";
import Echo from "laravel-echo";

window.Echo = new Echo({
  broadcaster: 'socket.io',
  // client: "",
  host: `${process.env.REACT_APP_API_URL_SOCKET}`
});


const statusCode = (cd) => {
  let bg = 'bg-orange-500'

  switch (cd) {
    case '1': // proses
      return bg = 'bg-orange-500'
    case '2': // Ambil
      return bg = 'bg-blue-500'
    default:
      return bg
  }
}

const statusName = (cd) => {
  let name = ''

  switch (cd) {
    case '1': // proses
      return name = 'Proses'
    case '2': // Ambil
      return name = 'Ambil'
    default:
      return name
  }
}


function DisplayFarmasi() {

  const [dataRacikan, setDataRacikan] = useState([])
  const [dataNonRacikan, setDataNonRacikan] = useState([])
  const [noAntrian, setNoAntrian] = useState("")


  window.Echo
    .channel(`laravel_database_callAntrianFarmasi`) // Broadcast channel name
    .listen('RequestAntrianFarmasi', (e) => { // Message name
      const filterData = e && e.data && e.data[0] // Pilih Data pertama => List Paling awal
  
      if(filterData && +filterData.statusCode === 2) { // Cek StatusCode === 2 (Boleh Diambil)
        setNoAntrian(filterData.noAntrian) // update State Antrian jika kondisi terpehuni
      }

      const racikan = (e.data || []).filter(el => +el.isRacikan === 1 && +el.statusCode !== 3)
      const nonRacikan = (e.data || []).filter(el => +el.isRacikan === 0 && +el.statusCode !== 3)
      setDataRacikan(racikan)
      setDataNonRacikan(nonRacikan)
      }
    );


  const handlePlay = () => {
    if(noAntrian !== "") { // No Antrian Tidak boleh Kosong
      setTimeout(() => {
        window.responsiveVoice
          .speak(`Nomor Antrian, ${(noAntrian || "")} menuju, Farmasi`, `Indonesian Female`, {
            rate: 0.9,
            pitch: 1,
            volume: 1,
          })
        setNoAntrian("")
      }, 1000)
    }
  }

  useEffect(() => {
    handlePlay()
  }, [noAntrian]);


  console.log(dataRacikan, 'dataRacikan')
  console.log(dataNonRacikan, 'dataNonRacikan')

  return (
    <div className='app'>
      <div className="lg:text-center flex mx-6 mt-6 gap-4">
        <div className='flex-row w-6/12 mr-3'>
          <div className='text-center'>
            <span className='text-5xl'>Racikan</span>
            {
              dataRacikan.map((el, k) => (
                <div className='flex my-4 rounded' key={k + 1}>
                  <div className='w-1/4 bg-red-rsia flex h-28 rounded mr-2'>
                    <span className='text-3xl font-extrabold m-auto text-white'>
                      {el.noAntrian}
                    </span>
                  </div>
                  <div className='w-2/4 bg-purple-600 h-28 flex rounded'>
                    <div className='m-auto'>
                      <h1 className='text-2xl font-bold text-white'>{el.namaPasien}</h1>
                      <h1 className='text-3xl font-bold text-white'>{(el.ambilResep || '').slice(0, 8)}</h1>
                    </div>
                  </div>
                  <div className={`w-1/4 flex h-28-20 rounded ml-2 ${statusCode(el.statusCode)}`}>
                    <span className='text-4xl font-extrabold m-auto text-white'>
                      {statusName(el.statusCode)}
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className='flex-row w-6/12 ml-3'>
          <div className='text-center'>
            <span className='text-5xl'>Non-Racikan</span>
            {
              dataNonRacikan.map((el, k) => (
                <div className='flex my-4 rounded' key={k + 1}>
                  <div className='w-1/4 bg-red-rsia flex h-28 rounded mr-2'>
                    <span className='text-3xl font-extrabold m-auto text-white'>
                      {el.noAntrian}
                    </span>
                  </div>
                  <div className='w-2/4 bg-purple-600 h-28 flex rounded'>
                    <div className='m-auto'>
                      <h1 className='text-2xl font-bold text-white'>{el.namaPasien}</h1>
                      <h1 className='text-3xl font-bold text-white'>{(el.ambilResep || '').slice(0, 8)}</h1>
                    </div>
                  </div>
                  <div className={`w-1/4 flex h-28 rounded ml-2 ${statusCode(el.statusCode)}`}>
                    <span className='text-4xl font-extrabold m-auto text-white'>
                      {statusName(el.statusCode)}
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DisplayFarmasi;

