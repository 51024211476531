import { Modal } from "antd";
import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Poliklinik = () => {
  const inputRef = useRef()
  const [isModalLama, setisModalLama] = useState(false);
  const [isModalBaru, setIsModalbaru] = useState(false);
  const history = useNavigate();

  useEffect(()=>{
    inputRef.current.focus();
  }, [])

  const handleOk = payload => {
    const {
      polyCode,
      docCode,
      jamPraktek,
      norm,
      nokartu,
      nik,
      hp,
      isJkn,
      jnsKunj,
      noreferensi,
      nomorAntrian,
      angkaAntrian,
      kodeBooking,
      namaPoli,
      isNew
    } = payload

    const sendData = {
      t_request:[
        {
          polyCode,
          docCode,
          jamPraktek,
          norm,
          nokartu,
          nik,
          hp,
          isJkn,
          jnsKunj,
          noreferensi,
          nomorAntrian,
          angkaAntrian,
          kodeBooking,
          namaPoli,
          isNew
        }
      ]
    }

    axios.post(`${process.env.REACT_APP_API_URL}/postNoAntrian`, sendData)
          .then(r => {
            if(r.data.acknowledge === 1) {
              Swal.fire({
                icon: 'success',
                title: 'Booking Sukses',
                text: r.data.message,
              })

              history(`/print_umum?queue=${nomorAntrian}&poli=${namaPoli}&new=${isNew}`);
            }else {
              Swal.fire({
                icon: 'error',
                title: 'Booking Gagal',
                text: r.data.message,
              })
            }
          })
  }

  const handleOkBaru = payload => {
    const {
      nama,
      tmplahir,
      tgllahir,
      polyCode,
      docCode,
      jamPraktek,
      nokartu,
      nik,
      hp,
      isJkn,
      jnsKunj,
      noreferensi,
      nomorAntrian,
      angkaAntrian,
      kodeBooking,
      namaPoli,
      isNew
    } = payload

    const sendData = {
      t_request:[
        {
          nama,
          tmplahir,
          tgllahir,
          polyCode,
          docCode,
          jamPraktek,
          nokartu,
          nik,
          hp,
          isJkn,
          jnsKunj,
          noreferensi,
          nomorAntrian,
          angkaAntrian,
          kodeBooking,
          namaPoli,
          isNew
        }
      ]
    }

    axios.post(`${process.env.REACT_APP_API_URL}/postNoAntrianBaru`, sendData)
          .then(r => {
            if(r.data.acknowledge === 1) {
              Swal.fire({
                icon: 'success',
                title: 'Booking Sukses',
                text: r.data.message,
              })

              history(`/print_umum?queue=${nomorAntrian}&poli=${namaPoli}&new=${isNew}`);
            }else {
              Swal.fire({
                icon: 'error',
                title: 'Booking Gagal',
                text: r.data.message,
              })
            }
          })
  }
  

  const handleCancel = () => {
    setisModalLama(false);
  };

  const handleCancelBaru = () => {
    setIsModalbaru(false);
  };

  const [pasien, setPasien] = useState({});
  const [noRm, setNoRm] = useState([]);


  const handleBack = () => {
      history('/');
  };

  const handleSearchPasien = (e) => {
    if(!noRm || noRm.length <  1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Anda Belum Memasukan Nomor Rekam Medik atau KTP Anda!",
      })
    }

    fetch(`${process.env.REACT_APP_API_URL}/getPasien/${noRm}`)
      .then(response => response.json())
      .then(r => {
        if(r.message === "Pasien Ditemukan!") {
          setPasien(r.data)
          setisModalLama(true)
        }else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: r.message,
          })
        }
      })

      e.preventDefault()
  }

  return (
    <div class="flex my-8 items-center justify-center">
      <div class="text-center">
        <div
          className="max-w-7xl mx-auto px-4 lg:py-10 lg:px-8 lg:items-center lg:text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block dark:text-skin-white">ANJUNGAN PASIEN MANDIRI</span>
          </h2>
        </div>
          <p class="text-black-200 text-4xl font-bold mb-8">PASIEN UMUM</p>
          <div class="max-w-lg mx-auto p-6 shadow-lg">
              <form class="mb-4" method='POST' onSubmit={handleSearchPasien}>
                  <input type="text" ref={inputRef} onChange={e => setNoRm(e.target.value)} value={noRm} class="w-full py-7 px-16 text-gray-700 text-xl rounded-full font-semibold border border-gray-300 p-2 shadow-inner" placeholder="Masukan Nomor RM / KTP" />
                  <button type="submit" class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-6 inline-block w-full">
                      CARI
                  </button>
                  <button type="button" onClick={() => setIsModalbaru(true)} class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-4 inline-block w-full">
                      PASIEN BARU
                  </button>
                  <button type="button" onClick={handleBack} class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-4 inline-block w-full">
                      KEMBALI
                  </button>
              </form>
              {/* <p class="text-gray-600 text-sm">Temukan produk atau layanan terbaik kami dengan mudah!</p> */}
          </div>
          <ModalPasieLama
            pasien={pasien}
            isModalLama={isModalLama}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          <ModalPasieBaru
             isModalBaru={isModalBaru}
             handleOkBaru={handleOkBaru}
             handleCancel={handleCancelBaru}
          />
      </div>
    </div>
  );
};


const ModalPasieLama = ({...props}) => {
  
  useEffect(()=>{
    fetch(`${process.env.REACT_APP_API_URL}/refPoli`)
      .then(response => response.json())
      .then(r => setPoli(r.data))
  }, [])
  
  const [poli, setPoli] = useState([]);
  const [dokter, setDokter] = useState([]);
  const [jadwal, setJadwal] = useState([]);
  const [kdPoli, setKodePoli] = useState("");
  const [kdDokter, setKodeDokter] = useState("");
  const [selectJadwal, setSelectJadwal] = useState("");
  const [queue, setQueue] = useState('');
  const [noQueue, setNoQueue] = useState('');
  const [bookingCode, setBookingCode] = useState('');
  const [namaPoli, setNamaPoli] = useState('');

  const {pasien, isModalLama, handleOk, handleCancel} = props

  const {
    pasien_id: noRM ="",
    nama_pasien: namaPasien="",
    no_ktp: nik="",
    hp="",
  } = pasien

  const payload = {
      polyCode : kdPoli,
      docCode  : kdDokter,
      jamPraktek: selectJadwal,
      norm:noRM,
      nokartu:"",
      nik,
      hp,
      isJkn: 0,
      jnsKunj: 1,
      noreferensi: "",
      nomorAntrian: queue,
      angkaAntrian:noQueue,
      kodeBooking: bookingCode,
      namaPoli,
      isNew: 0
    }

  const handleAmbilAntrian = e => {
    Swal.fire({
      title: 'Anda Yakin ?',
      text: "Data yang dimasukan sudah benar",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        handleOk(payload)
      }
    })
   
    e.preventDefault()
  }


  

  const handleSearchDokter = e => {
    setKodePoli(e.target.value)
    fetch(`${process.env.REACT_APP_API_URL}/refDokter/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setDokter(r.data) : setDokter([])
      })
  }

  const handleSearchJadwal = e => {
    setKodeDokter(e.target.value)

    fetch(`${process.env.REACT_APP_API_URL}/refJadwal/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setJadwal(r.data) : (setJadwal([]))
    })
  }

  const handleSelectJadwal = e => {
    setSelectJadwal(e.target.value)
    axios.post(`${process.env.REACT_APP_API_URL}/getNoAntrian`, {polyCode:kdPoli, docCode:kdDokter})
    .then(r => {
      setQueue(r.data.noAntri)
      setNoQueue(r.data.count) 
      setBookingCode(r.data.kodeBooking)
      setNamaPoli(r.data.namaPoli)
    })
  }

  return (
    <>
      <Modal 
        title="DATA PASIEN" 
        open={isModalLama} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={false}
        style={{ top: 20 }}
        // width="100%"
      >
         <div class="flex my-2 w-full items-center justify-center">
          <div class="text-center w-full">
            <div class="mx-auto p-2">
                <form class="mb-4" method='POST' onSubmit={handleAmbilAntrian}>
                  <div class="mb-2">
                    <label for="name" class="block text-sm font-medium text-gray-600">NO. RM</label>
                    <input type="text" id="name" required disabled name="name" value={noRM} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">NAMA</label>
                    <input type="text" id="name" required disabled name="name" value={namaPasien} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  {poli.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">POLIKLINIK</label>
                      <select type="text" id="name" required name="name" value={kdPoli} onChange={handleSearchDokter} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {poli.map((el, key) => (
                          <option value={el.poli_id} key={key}>{el.nama_poli}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">DOKTER</label>
                      <select type="text" id="name" required name="name" value={kdDokter} onChange={handleSearchJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {dokter.map((el, key) => (
                          <option value={el.dokter_id} key={key}>{el.nama_dokter}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {jadwal.length && dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">JADWAL</label>
                      <select type="text" id="name" required name="name" value={selectJadwal} onChange={handleSelectJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {jadwal.map((el, key) => (
                          <option value={`${el.buka}-${el.tutup}`} key={key}>{el.buka}-{el.tutup}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  <button type="submit" class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-6 inline-block w-full">
                      AMBIL ANTRIAN
                  </button>
                </form>
                {/* <p class="text-gray-600 text-sm">Temukan produk atau layanan terbaik kami dengan mudah!</p> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ModalPasieBaru= ({...props}) => {
  
  useEffect(()=>{
    fetch(`${process.env.REACT_APP_API_URL}/refPoli`)
      .then(response => response.json())
      .then(r => setPoli(r.data))
  }, [])
  
  const [poli, setPoli] = useState([]);
  const [dokter, setDokter] = useState([]);
  const [jadwal, setJadwal] = useState([]);
  const [noKtp, setNoKtp] = useState("");
  const [namaPasien, setNamaPasien] = useState("");
  const [kdPoli, setKodePoli] = useState("");
  const [kdDokter, setKodeDokter] = useState("");
  const [selectJadwal, setSelectJadwal] = useState("");
  const [queue, setQueue] = useState('');
  const [noQueue, setNoQueue] = useState('');
  const [bookingCode, setBookingCode] = useState('');
  const [namaPoli, setNamaPoli] = useState('');

  const { isModalBaru, handleOkBaru, handleCancel} = props

  const payload = {
      nama : namaPasien,
      tmplahir: "",
      tgllahir: "",
      polyCode : kdPoli,
      docCode  : kdDokter,
      jamPraktek: selectJadwal,
      nokartu: "",
      nik: noKtp,
      hp: "",
      isJkn: 0,
      jnsKunj: 1,
      noreferensi: "",
      nomorAntrian: queue,
      angkaAntrian:noQueue,
      kodeBooking: bookingCode,
      namaPoli,
      isNew: 1
    }

  const handleAmbilAntrian = e => {
    Swal.fire({
      title: 'Anda Yakin ?',
      text: "Data yang dimasukan sudah benar",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        handleOkBaru(payload)
      }
    })
   
    e.preventDefault()
  }


  

  const handleSearchDokter = e => {
    setKodePoli(e.target.value)
    fetch(`${process.env.REACT_APP_API_URL}/refDokter/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setDokter(r.data) : setDokter([])
      })
  }

  const handleSearchJadwal = e => {
    setKodeDokter(e.target.value)

    fetch(`${process.env.REACT_APP_API_URL}/refJadwal/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setJadwal(r.data) : (setJadwal([]))
    })
  }

  const handleSelectJadwal = e => {
    setSelectJadwal(e.target.value)
    axios.post(`${process.env.REACT_APP_API_URL}/getNoAntrian`, {polyCode:kdPoli, docCode:kdDokter})
    .then(r => {
      setQueue(r.data.noAntri)
      setNoQueue(r.data.count) 
      setBookingCode(r.data.kodeBooking)
      setNamaPoli(r.data.namaPoli)
    })
  }

  return (
    <>
      <Modal 
        title="DATA PASIEN" 
        open={isModalBaru} 
        onOk={handleOkBaru} 
        onCancel={handleCancel}
        footer={false}
        style={{ top: 20 }}
        // width="100%"
      >
         <div class="flex my-2 w-full items-center justify-center">
          <div class="text-center w-full">
            <div class="mx-auto p-2">
                <form class="mb-4" method='POST' onSubmit={handleAmbilAntrian}>
                  <div class="mb-2">
                    <label for="name" class="block text-sm font-medium text-gray-600">NO KTP</label>
                    <input type="text" id="name" required name="name" onChange={e => setNoKtp(e.target.value)} value={noKtp} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">NAMA</label>
                    <input type="text" id="name" required name="name" onChange={e => setNamaPasien(e.target.value)} value={namaPasien} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  {poli.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">POLIKLINIK</label>
                      <select type="text" id="name" required name="name" value={kdPoli} onChange={handleSearchDokter} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {poli.map((el, key) => (
                          <option value={el.poli_id} key={key}>{el.nama_poli}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">DOKTER</label>
                      <select type="text" id="name" required name="name" value={kdDokter} onChange={handleSearchJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {dokter.map((el, key) => (
                          <option value={el.dokter_id} key={key}>{el.nama_dokter}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {jadwal.length && dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">JADWAL</label>
                      <select type="text" id="name" required name="name" value={selectJadwal} onChange={handleSelectJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {jadwal.map((el, key) => (
                          <option value={`${el.buka}-${el.tutup}`} key={key}>{el.buka}-{el.tutup}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  <button type="submit" class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-6 inline-block w-full">
                      AMBIL ANTRIAN
                  </button>
                </form>
                {/* <p class="text-gray-600 text-sm">Temukan produk atau layanan terbaik kami dengan mudah!</p> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Poliklinik
