import { Modal } from "antd";
import axios from "axios";
import moment from "moment/moment";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Poliklinik = () => {
  const inputRef = useRef()
  const [isModalLama, setIsModalLama] = useState(false);
  const [isModalBaru, setIsModalBaru] = useState(false);
  const history = useNavigate();

  useEffect(()=>{
    inputRef.current.focus();
  }, [])

  const handleOk = payload => {
    const {
      polyCode,
      docCode,
      jamPraktek,
      norm,
      nokartu,
      nik,
      hp,
      isJkn,
      jnsKunj,
      noreferensi,
      nomorAntrian,
      angkaAntrian,
      kodeBooking,
      namaPoli,
      asalFaskes,
      kodePoliRujukan,
      namaPoliRujukan,
      jmlSep,
      isNew
    } = payload

    const sendData = {
      t_request:[
        {
          polyCode,
          docCode,
          jamPraktek,
          norm,
          nokartu,
          nik,
          hp,
          isJkn,
          jnsKunj,
          noreferensi,
          nomorAntrian,
          angkaAntrian,
          kodeBooking,
          namaPoli,
          asalFaskes,
          kodePoliRujukan,
          namaPoliRujukan,
          jmlSep,
          isNew
        }
      ]
    }

    axios.post(`${process.env.REACT_APP_API_URL}/postNoAntrianBpjsManual`, sendData)
          .then(r => {
            if(r.data.acknowledge === 1) {
              Swal.fire({
                icon: 'success',
                title: 'Booking Sukses',
                text: r.data.message,
              })

              history(`/print_umum?queue=${nomorAntrian}&poli=${namaPoli}&new=${isNew}`);
            }else {
              Swal.fire({
                icon: 'error',
                title: 'Booking Gagal',
                text: r.data.message,
              })
            }
          })
  }

  const handleOkBaru = payload => {
    const {
      nama,
      tmplahir,
      tgllahir,
      polyCode,
      docCode,
      jamPraktek,
      nokartu,
      nik,
      hp,
      isJkn,
      jnsKunj,
      noreferensi,
      nomorAntrian,
      angkaAntrian,
      kodeBooking,
      namaPoli,
      asalFaskes,
      kodePoliRujukan,
      namaPoliRujukan,
      jmlSep,
      isNew
    } = payload

    const sendData = {
      t_request:[
        {
          nama,
          tmplahir,
          tgllahir,
          polyCode,
          docCode,
          jamPraktek,
          nokartu,
          nik,
          hp,
          isJkn,
          jnsKunj,
          noreferensi,
          nomorAntrian,
          angkaAntrian,
          kodeBooking,
          namaPoli,
          asalFaskes,
          kodePoliRujukan,
          namaPoliRujukan,
          jmlSep,
          isNew
        }
      ]
    }

    axios.post(`${process.env.REACT_APP_API_URL}/postAntrianBaruBpjs`, sendData)
          .then(r => {
            if(r.data.acknowledge === 1) {
              Swal.fire({
                icon: 'success',
                title: 'Booking Sukses',
                text: r.data.message,
              })

              history(`/print_umum?queue=${nomorAntrian}&poli=${namaPoli}&new=${isNew}`);
            }else {
              Swal.fire({
                icon: 'error',
                title: 'Booking Gagal',
                text: r.data.message,
              })
            }
          })
  }
  

  const handleCancel = () => {
    setIsModalLama(false);
  };

  const handleCancelBaru = () => {
    setIsModalBaru(false);
  };

  const [pasien, setPasien] = useState({});
  const [nokartu, setNoKartu] = useState([]);
  const [rujukan, setRujukan] = useState({});
  
  const handleBack = () => {
      history('/');
  };

  const handleSearchPasien = (e) => {
    if(!nokartu || nokartu.length <  1) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "Anda Belum Memasukan Nomor Rekam Medik atau KTP Anda!",
      })
    }

    fetch(`${process.env.REACT_APP_API_URL}/getPasienBpjs/${nokartu}`)
      .then(response => response.json())
      .then(peserta => {
        if(peserta.acknowledge === 1) {
          fetch(`${process.env.REACT_APP_API_URL}/getReferensi/${peserta.data.peserta.noKartu}`)
          .then(response => response.json())
          .then(r => {
            // if(r.acknowledge === 1) {
              
              if(peserta.data.peserta.mr.noMR === null) {
                setPasien(peserta.data)  
                setIsModalBaru(true)
              }else{
                setPasien(peserta.data)
                setIsModalLama(true)
              }
              setRujukan(r.data)
            // }else{
            //   Swal.fire({
            //     icon: 'error',
            //     title: 'Oops...',
            //     text: r.message,
            //   })
            // }
          })
        }else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: peserta.message,
          })
        }
      })

      e.preventDefault()
  }

  return (
    <div class="flex my-8 items-center justify-center">
      <div class="text-center">
        <div
          className="max-w-7xl mx-auto px-4 lg:py-10 lg:px-8 lg:items-center lg:text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block dark:text-skin-white">ANJUNGAN PASIEN MANDIRI</span>
          </h2>
        </div>
          <p class="text-black-200 text-4xl font-bold mb-8">PASIEN BPJS</p>
          <div class="max-w-lg mx-auto p-6 shadow-lg">
              <form class="mb-4" method='POST' onSubmit={handleSearchPasien}>
                  <input type="text" ref={inputRef} onChange={e => setNoKartu(e.target.value)} value={nokartu} class="w-full py-7 px-16 text-gray-700 text-xl rounded-full font-semibold border border-gray-300 p-2 shadow-inner" placeholder="Masukan Nomor Kartu / KTP" />
                  <button type="submit" class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-6 inline-block w-full">
                      CARI
                  </button>
                  <button type="button" onClick={handleBack} class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-4 inline-block w-full">
                      KEMBALI
                  </button>
              </form>
              {/* <p class="text-gray-600 text-sm">Temukan produk atau layanan terbaik kami dengan mudah!</p> */}
          </div>
          <ModalPasieLama
            pasien={pasien}
            rujukan={rujukan}
            isModalLama={isModalLama}
            handleOk={handleOk}
            handleCancel={handleCancel}
          />
          <ModalPasieBaru
            rujukan={rujukan}
            pasien={pasien}
            isModalBaru={isModalBaru}
            handleOkBaru={handleOkBaru}
            handleCancel={handleCancelBaru}
          />
      </div>
    </div>
  );
};


const ModalPasieLama = ({...props}) => {
  
  useEffect(()=>{
    fetch(`${process.env.REACT_APP_API_URL}/refPoli`)
      .then(response => response.json())
      .then(r => setPoli(r.data))
  }, [])
  
  const [poli, setPoli] = useState([]);
  const [dokter, setDokter] = useState([]);
  const [jadwal, setJadwal] = useState([]);
  const [rujukanSelected, setRujukanSelected] = useState({});
  const [kdPoli, setKodePoli] = useState("");
  const [kdKunjungan, setKdKunjungan] = useState("");
  const [kdDokter, setKodeDokter] = useState("");
  const [jnsKunj, setJnsKunj] = useState("");
  const [selectJadwal, setSelectJadwal] = useState("");
  const [queue, setQueue] = useState('');
  const [noQueue, setNoQueue] = useState('');
  const [bookingCode, setBookingCode] = useState('');
  const [namaPoli, setNamaPoli] = useState('');

  const {pasien, rujukan, isModalLama, handleOk, handleCancel} = props

  const {
    peserta={}
  } = pasien

  const {
    mr={},
    nama: namaPasien="",
    nik="",
    noKartu:nokartu="",

  } = peserta

  const {
    noMR:noRM="",
    noTelepon: hp=""
  } = mr

  const {
    rujukan: dataRujukan = [],
    asalFaskes,
  } = rujukan

  const {
    poliRujukan = {}
  } = rujukanSelected

  const {
    kode: kodePoliRujukan,
    nama: namaPoliRujukan,
  } = poliRujukan
  
  const payload = {
      polyCode : kdPoli,
      docCode  : kdDokter,
      jamPraktek: selectJadwal,
      norm:noRM,
      nokartu,
      nik,
      hp,
      isJkn: 1,
      jnsKunj,
      noreferensi: kdKunjungan,
      nomorAntrian: queue,
      angkaAntrian:noQueue,
      kodeBooking: bookingCode,
      namaPoli,
      asalFaskes,
      kodePoliRujukan,
      namaPoliRujukan,
      isNew: 0
    }

  const handleAmbilAntrian = e => {
    Swal.fire({
      title: 'Anda Yakin ?',
      text: "Data yang dimasukan sudah benar",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(payload)
        handleOk(payload)
      }
    })
   
    e.preventDefault()
  }

  const handleSearchDokter = e => {
    setKodePoli(e.target.value)
    setKodeDokter("")
    fetch(`${process.env.REACT_APP_API_URL}/refDokter/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setDokter(r.data) : setDokter([])
      })
  }

  const handleSearchJadwal = e => {
    setKodeDokter(e.target.value)
    setSelectJadwal("")

    fetch(`${process.env.REACT_APP_API_URL}/refJadwal/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setJadwal(r.data) : (setJadwal([]))
    })
  }

  const handleSelectJadwal = e => {
    setSelectJadwal(e.target.value)
    axios.post(`${process.env.REACT_APP_API_URL}/getNoAntrian`, {polyCode:kdPoli, docCode:kdDokter})
    .then(r => {
      setQueue(r.data.noAntri)
      setNoQueue(r.data.count) 
      setBookingCode(r.data.kodeBooking)
      setNamaPoli(r.data.namaPoli)
    })
  }

  const handleCloseModal = () => {
    setKdKunjungan("")
    setKodePoli("")
    setKodeDokter("")
    setSelectJadwal("")
    handleCancel()
  }

  const HitungSelisih = tgl => {
    let tglNow = new moment(); 
    var tglKunj = new moment(tgl); 
    var selisih = tglNow.diff(tglKunj, 'days');

    return selisih
  }

  return (
    <>
      <Modal 
        title="DATA PASIEN" 
        open={isModalLama} 
        onOk={handleOk} 
        onCancel={handleCloseModal}
        footer={false}
        style={{ top: 20 }}
        // width="100%"
      >
         <div class="flex my-2 w-full items-center justify-center">
          <div class="text-center w-full">
            <div class="mx-auto p-2">
                <form class="mb-4" method='POST' onSubmit={handleAmbilAntrian}>
                  <div class="mb-2">
                    <label for="name" class="block text-sm font-medium text-gray-600">NO. RM</label>
                    <input type="text" id="name" required disabled name="name" value={noRM} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">NAMA</label>
                    <input type="text" id="name" required disabled name="name" value={namaPasien} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">Jenis Kunjungan</label>
                    <select type="text" id="name" required name="name" value={jnsKunj} onChange={e => setJnsKunj(e.target.value)} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                      <option value="1">Rujukan FKTP</option>
                      <option value="2">Rujukan Internal</option>
                      <option value="3">Kontrol</option>
                      <option value="4">Rujukan Antar RS</option>
                    </select>
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">NO REFERENSI</label>
                    <input type="text" id="name" required name="name" value={kdKunjungan} onChange={e => setKdKunjungan(e.target.value)} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  {poli.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">POLIKLINIK</label>
                      <select type="text" id="name" required name="name" value={kdPoli} onChange={handleSearchDokter} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {poli.map((el, key) => (
                          <option value={el.poli_id} key={key}>{el.nama_poli}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">DOKTER</label>
                      <select type="text" id="name" required name="name" value={kdDokter} onChange={handleSearchJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {dokter.map((el, key) => (
                          <option value={el.dokter_id} key={key}>{el.nama_dokter}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {jadwal.length && dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">JADWAL</label>
                      <select type="text" id="name" required name="name" value={selectJadwal} onChange={handleSelectJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {jadwal.map((el, key) => (
                          <option value={`${el.buka}-${el.tutup}`} key={key}>{el.buka}-{el.tutup}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  <button type="submit" class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-6 inline-block w-full">
                      AMBIL ANTRIAN
                  </button>
                </form>
                {/* <p class="text-gray-600 text-sm">Temukan produk atau layanan terbaik kami dengan mudah!</p> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ModalPasieBaru= ({...props}) => {
  
  useEffect(()=>{
    fetch(`${process.env.REACT_APP_API_URL}/refPoli`)
      .then(response => response.json())
      .then(r => setPoli(r.data))
  }, [])
  
  const [poli, setPoli] = useState([]);
  const [dokter, setDokter] = useState([]);
  const [jadwal, setJadwal] = useState([]);
  const [rujukanSelected, setRujukanSelected] = useState({});
  const [kdPoli, setKodePoli] = useState("");
  const [kdDokter, setKodeDokter] = useState("");
  const [hp, setHp] = useState("");
  const [kdKunjungan, setKdKunjungan] = useState("");
  const [jnsKunj, setJnsKunj] = useState("");
  const [selectJadwal, setSelectJadwal] = useState("");
  const [queue, setQueue] = useState('');
  const [noQueue, setNoQueue] = useState('');
  const [bookingCode, setBookingCode] = useState('');
  const [namaPoli, setNamaPoli] = useState('');

  const { pasien, rujukan, isModalBaru, handleOkBaru, handleCancel} = props

  const {
    peserta={}
  } = pasien

  const {
    nama: namaPasien="",
    nik="",
    noKartu:nokartu="",
    tglLahir:tgllahir=""
  } = peserta

  const {
    rujukan: dataRujukan = [],
    asalFaskes: asalfaskes="",
  } = rujukan

  const {
    poliRujukan = {}
  } = rujukanSelected

  const {
    kode: kodePoliRujukan="",
    nama: namaPoliRujukan="",
  } = poliRujukan

  const payload = {
      nama : namaPasien,
      tmplahir: "",
      tgllahir,
      polyCode : kdPoli,
      docCode  : kdDokter,
      jamPraktek: selectJadwal,
      nokartu,
      nik,
      hp,
      isJkn: 1,
      jnsKunj,
      noreferensi: kdKunjungan,
      nomorAntrian: queue,
      angkaAntrian:noQueue,
      kodeBooking: bookingCode,
      namaPoli,
      asalFaskes:asalfaskes,
      kodePoliRujukan,
      namaPoliRujukan,
      isNew: 1
    }

  const handleAmbilAntrian = e => {
    Swal.fire({
      title: 'Anda Yakin ?',
      text: "Data yang dimasukan sudah benar",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        handleOkBaru(payload)
      }
    })
   
    e.preventDefault()
  }

  const handleSearchDokter = e => {
    setKodePoli(e.target.value)
    setKodeDokter("")
    fetch(`${process.env.REACT_APP_API_URL}/refDokter/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setDokter(r.data) : setDokter([])
      })
  }

  const handleSearchJadwal = e => {
    setKodeDokter(e.target.value)
    setSelectJadwal("")

    fetch(`${process.env.REACT_APP_API_URL}/refJadwal/${e.target.value}`)
      .then(response => response.json())
      .then(r => {
        r.data.length ? setJadwal(r.data) : (setJadwal([]))
    })
  }

  const handleSelectJadwal = e => {
    setSelectJadwal(e.target.value)
    axios.post(`${process.env.REACT_APP_API_URL}/getNoAntrian`, {polyCode:kdPoli, docCode:kdDokter})
    .then(r => {
      setQueue(r.data.noAntri)
      setNoQueue(r.data.count) 
      setBookingCode(r.data.kodeBooking)
      setNamaPoli(r.data.namaPoli)
    })
  }

  const handleCloseModal = () => {
    setKdKunjungan("")
    setKodePoli("")
    setKodeDokter("")
    setSelectJadwal("")
    handleCancel()
  }


  return (
    <>
      <Modal 
        title="DATA PASIEN" 
        open={isModalBaru} 
        onOk={handleOkBaru} 
        onCancel={handleCloseModal}
        footer={false}
        style={{ top: 20 }}
        // width="100%"
      >
         <div class="flex my-2 w-full items-center justify-center">
          <div class="text-center w-full">
            <div class="mx-auto p-2">
                <form class="mb-4" method='POST' onSubmit={handleAmbilAntrian}>
                  <div class="mb-2">
                    <label for="name" class="block text-sm font-medium text-gray-600">NO KTP</label>
                    <input type="text" id="name" required name="name" disabled value={nik} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">NAMA</label>
                    <input type="text" id="name" required name="name" disabled value={namaPasien} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">No HP</label>
                    <input type="text" id="name" required name="hp" onChange={e => setHp(e.target.value)} value={hp} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">Jenis Kunjungan</label>
                    <select type="text" id="name" required name="name" value={jnsKunj} onChange={e => setJnsKunj(e.target.value)} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                      <option value="1">Rujukan FKTP</option>
                      <option value="2">Rujukan Internal</option>
                      <option value="3">Kontrol</option>
                      <option value="4">Rujukan Antar RS</option>
                    </select>
                  </div>
                  <div class="mb-4">
                    <label for="name" class="block text-sm font-medium text-gray-600">NO REFERENSI</label>
                    <input type="text" id="name" required name="name" value={kdKunjungan} onChange={e => setKdKunjungan(e.target.value)} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200" />
                  </div>
                  {poli.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">POLIKLINIK</label>
                      <select type="text" id="name" required name="name" value={kdPoli} onChange={handleSearchDokter} class="py-4 text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {poli.map((el, key) => (
                          <option value={el.poli_id} key={key}>{el.nama_poli}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">DOKTER</label>
                      <select type="text" id="name" required name="name" value={kdDokter} onChange={handleSearchJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {dokter.map((el, key) => (
                          <option value={el.dokter_id} key={key}>{el.nama_dokter}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  {jadwal.length && dokter.length ? (
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-600">JADWAL</label>
                      <select type="text" id="name" required name="name" value={selectJadwal} onChange={handleSelectJadwal} class="py-4 text-lg text-center font-semibold mt-1 p-2 w-full border rounded-md focus:ring focus:ring-blue-200">
                        <option value="">-</option>
                        {jadwal.map((el, key) => (
                          <option value={`${el.buka}-${el.tutup}`} key={key}>{el.buka}-{el.tutup}</option>
                        ))}
                      </select>
                    </div>
                  ) : ''}
                  <button type="submit" class="py-4 px-8 bg-green-500 hover:bg-green-700 text-white font-semibold rounded-full focus:outline-none mt-6 inline-block w-full">
                      AMBIL ANTRIAN
                  </button>
                </form>
                {/* <p class="text-gray-600 text-sm">Temukan produk atau layanan terbaik kami dengan mudah!</p> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Poliklinik
