import "./App.css";
import { Route, Routes, HashRouter} from "react-router-dom";
import Home from "./components/pages/Home";
import PasienBPJS from "./components/pages/Pasienbpjs";
import PasienUmum from "./components/pages/Pasienumum";
import PasienManual from "./components/pages/PasienManual";
import MyApp from "./components/pages/test";
import PrintUmum from "./components/pages/Print";
import Display from "./components/pages/Display";
import Farmasi from "./components/pages/Farmasi";
import Kamar from "./components/pages/Kamar";

function App() {
  const routes = [
    {
      path: "/pasienbpjs",
      component: <PasienBPJS />,
      exact:true
    },
    {
      path: "/pasienmanual",
      component: <PasienManual />,
      exact:true
    },
    {
      path: "/pasienumum",
      component: <PasienUmum />,
      exact:true
    },
    {
      path: "/print_umum",
      component: <PrintUmum />,
      exact:true
    },
    {
      path: "/display",
      component: <Display />,
      exact:true
    },
    {
      path: "/farmasi",
      component: <Farmasi />,
      exact:true
    },
    {
      path: "/kamar",
      component: <Kamar />,
      exact:true
    },
  ]

  return (
    <MyApp >
      <HashRouter hashType='hashbang'>
          <Routes>
            <Route exact path="/" element={<Home />} />
            {routes.map(route => (
              <Route
                path={route.path}
                element={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
          </Routes>
        </HashRouter>
    </MyApp>

  );
}

export default App;
